import axios from 'axios';
import { DefaultSeo } from 'next-seo';
import App from 'next/app';
import objectFitImages from 'object-fit-images';
import { toast } from 'react-toastify';
import { SWRConfig } from 'swr';

import { Providers } from '~components';
import { seo, apiUrl } from '~config';
import { initSentry } from '~lib';
import CSSreset from '~styles/CSSreset';

import 'react-toastify/dist/ReactToastify.css';

import '../../public/static/fonts/stylesheet.css';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  initSentry();
}

axios.defaults.baseURL = apiUrl;
axios.defaults.withCredentials = true;

toast.configure({
  position: 'bottom-right',
  autoClose: 4000,
  closeButton: false,
  hideProgressBar: true,
});
export default class MyApp extends App {
  componentDidMount() {
    objectFitImages();
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <Providers>
        <SWRConfig
          value={{
            // @ts-ignore
            dedupingInterval: 0,
            fetcher: (url, params) =>
              axios(url, { params }).then((res) => res.data),
          }}
        >
          <CSSreset />
          <DefaultSeo {...seo} />
          <Component {...pageProps} />
        </SWRConfig>
      </Providers>
    );
  }
}
